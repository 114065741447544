<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm @submit.prevent="onLogin">
                                    <h1>{{ this.$t('pages.login.login') }}</h1>
                                    <p class="text-muted">{{ this.$t('pages.login.signIntoYourAccount') }}</p>

                                    <CInput :placeholder="this.$t('pages.login.username')"
                                            autocomplete="username email" v-model="values.username">
                                        <template #prepend-content>
                                            <CIcon name="cil-user" />
                                        </template>
                                    </CInput>
                                    <p class="errors" v-if="!!errors.username">{{errors.username}}</p>

                                    <CInput :placeholder="this.$t('pages.login.password')"
                                            type="password"
                                            autocomplete="curent-password" v-model="values.password">
                                        <template #prepend-content>
                                            <CIcon name="cil-lock-locked" />
                                        </template>
                                    </CInput>
                                    <p class="errors" v-if="!!errors.password">{{errors.password}}</p>
                                    <CRow>
                                        <CCol col="6" class="text-left">
                                            <CButton color="primary" class="px-4" :disabled="status.loggingIn" type="submit">{{ this.$t('pages.login.login') }}</CButton>
                                            <img v-show="status.loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        </CCol>
                                        <CCol col="6" class="text-right">
                                            <CButton color="link" class="px-0" @click="onForgotPassword">{{ this.$t('pages.login.forgotPassword') }}?</CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                        <!--<CCard color="primary"
                               text-color="white"
                               class="text-center py-5 d-md-down-none"
                               body-wrapper>
                            <CCardBody>
                                <h2>{{ this.$t('pages.login.signup') }}</h2>
                                <p>{{ this.$t('pages.login.signupabount') }}</p>
                                <CButton color="light"
                                         variant="outline"
                                         >
                                    {{ this.$t('pages.login.registerNow') }}!
                                </CButton>
                            </CCardBody>
                        </CCard>-->
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import _ from 'lodash'
    import * as yup from 'yup'

    const loginSchema = yup.object().shape({
        username: yup.string().required('Nhập định danh'),
        password: yup.string().required('Nhập mật khẩu')
    })

    export default {
        name: 'Login',
        data() {
            return {
                values: {
                    username: '',
                    password: ''
                },
                errors: {
                    username: '',
                    password: ''
                }
            }
        },
        computed: {
            ...mapState('auth',['status'])
        },
        methods: {
            ...mapActions('auth', ['login']),

            validate(field) {
                loginSchema.validateAt(field, this.values)
                    .then(() => this.errors[field] = '')
                    .catch((err) => {
                        this.errors[err.path] = err.message
                        console.log('validate error', err);
                    });
            },
            async onLogin() {
                try {
                    await loginSchema.validate(this.values, { abortEarly: false });
                    this.errors = {};
                }
                catch (err) {
                    _.forEach(err.inner, error => {
                        this.errors[error.path] = error.message;
                        console.log("Validation error", { path: error.path, message: error.message })
                    })
                    return;
                }

                var result = await this.login({ username: this.values.username, password: this.values.password });
                if (result) {
                    this.$router.push("/");
                } else {
                    this.$store.dispatch('alert/error', "Không thể đăng nhập. Bạn hãy kiểm tra lại thông tin", { root: true });
                }
            },
            async onForgotPassword() {
                window.open(`${process.env.VUE_APP_APIURL}/passwordrecoveryrequest`);
            }
        }
    }
</script>
<style scoped>
    .errors{
        color:red
    }
</style>